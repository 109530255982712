import { Grid, Icon } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Invoices from "./Invoices";
// import Transactions from "./Transactions";
// import BillingInformation from "./components/BillingInformation";
import CreditPanel from "./components/CreditPanel";
// import Coupon from "./components/Coupon";
import { useAuth } from "../../auth-context/auth.context";
import UserApi from "api/user";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useState } from "react";
// import JobApi from "api/job";

// function JobToBill(job_string) {
//   let job = JSON.parse(job_string);
//   return {
//     id: job.id,
//     apps: job.requirement.opk,
//     status: job.status,
//     time_started: job.time_started,
//     time_completed: job.time_completed,
//     cost: job.cost,
//   };
// }

function Credits() {
  const history = useHistory();
  const { user, setUser } = useAuth();
  const [points, setPoints] = useState(localStorage.getItem("points"));
  // const [coupons, setCoupons] = useState([]);
  // const [jobBills, setJobBills] = useState([]);

  const getCredit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    try {
      let response = await UserApi.GetCredit(user);
      if (response.data && response.data.reply_msg && response.data.reply_msg.success == false) {
        console.log(response.data.reply_msg.error);
        await setUser(null);
        localStorage.removeItem("user");
        return history.push("/authentication/sign-in");
      }

      setPoints(response.data.points);
      localStorage.setItem("points", points.toString());
    } catch (err) {
      console.log(err);
    }
  };

  // const getCoupons = async (event) => {
  //   if (event) {
  //     event.preventDefault();
  //   }

  //   try {
  //     let response = await UserApi.GetCoupons(user);
  //     if (response.data && response.data.reply_msg && response.data.reply_msg.success == false) {
  //       return console.log(response.data.reply_msg.error);
  //     }

  //     let coupons = response.data.coupons.map((coupon_string) => JSON.parse(coupon_string));
  //     setCoupons(coupons);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // const queryJobs = async (event) => {
  //   if (event) {
  //     event.preventDefault();
  //   }

  //   try {
  //     let response = await JobApi.Query(user);

  //     if (response.data && response.data.success === false) {
  //       console.log(response.data.msg);
  //       return;
  //     }

  //     let jobBills = response.data.jobs.map(JobToBill);
  //     setJobBills(jobBills);
  //   } catch (err) {
  //     if (err.response) {
  //       console.log(err.response.data.msg);
  //     }
  //     return;
  //   }
  // };

  const UpdatePage = async (event) => {
    await getCredit(event);
    // await getCoupons(event);
    // await queryJobs(event);
  };

  // const UseCoupon = async (couponID) => {
  //   try {
  //     let response = await UserApi.UseCoupon(couponID, user.email, user.token_auth);
  //     if (response.data && response.data.reply_msg && response.data.reply_msg.success == false) {
  //       return console.log(response.data.error);
  //     }

  //     setCredit(response.data.credit_new);
  //     localStorage.setItem("user_credit", response.data.credit_new);
  //     let newCoupons = [];
  //     coupons.forEach((coupon) => {
  //       if (coupon.id == couponID) {
  //         coupon.used = true;
  //       }
  //       newCoupons.push(coupon);
  //     });
  //     setCoupons(newCoupons);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiButton variant="gradient" buttonColor="light" onClick={UpdatePage}>
        <Icon className="font-bold">refresh</Icon>
        &nbsp;&nbsp;&nbsp;update
      </SuiButton>
      <SuiBox mt={4}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={2}>
              <CreditPanel
                icon="account_balance"
                title="Points Available"
                description=""
                value={Number.parseFloat(points).toFixed(3)}
              />
            </Grid>
            {/* {coupons.map((coupon) => (
              <Grid item xs={12} md={4} key={coupon.id}>
                <Coupon coupon={coupon} useCoupon={UseCoupon} />
              </Grid>
            ))} */}
            <Grid item xs={12} md={4}>
              {/* <DefaultInfoCard icon="paypal" title="Paid Points" value="+2.00" /> */}
              {/* <Invoices /> */}
            </Grid>
            <Grid item xs={12} md={4}>
              {/* <Transactions /> */}
            </Grid>
          </Grid>
        </SuiBox>
        <SuiBox mb={3}>
          <Grid item xs={36} md={12}>
            {/* <BillingInformation jobBills={jobBills} /> */}
          </Grid>
        </SuiBox>
      </SuiBox>
    </DashboardLayout>
  );
}

export default Credits;
