// import logoGromacs from "assets/images/small-logos/logo-gromacs.png";

// function Completion({ value, color }) {
//   return (
//     <SuiBox display="flex" alignItems="center">
//       <SuiTypography variant="caption" textColor="text" fontWeight="medium">
//         {value}%&nbsp;
//       </SuiTypography>
//       <SuiBox width="8rem">
//         <SuiProgress value={value} color={color} gradient noLabel />
//       </SuiBox>
//     </SuiBox>
//   );
// }

function JobSort(a, b) {
  if (a.submit > b.submit) {
    return -1;
  } else if (a.submit < b.submit) {
    return 1;
  } else {
    return 0;
  }
}

function display_time(time) {
  return time.slice(0, 10) + " " + time.slice(11, 19);
}

function JobToRow(job) {
  return {
    // id: [logoGromacs, job.id],
    id: job.id,
    app: job.app,
    content: job.repr,
    submit: display_time(job.time_created),
    start:
      // job.time_start > job.time_created ? new Date(job.time_start).toISOString() : "Not Started",
      job.time_start > job.time_created ? display_time(job.time_start) : "Not Started",
    end: job.time_end > job.time_created ? display_time(job.time_end) : "Not Completed",
    status: job.status,
  };
}

export function ConvertJobsToRows(jobs) {
  let new_jobs = jobs.map(JobToRow);
  new_jobs.sort(JobSort);
  return new_jobs;
}

export default function JobTable(jobs) {
  const rows = ConvertJobsToRows(jobs);
  return {
    columns: [
      { name: "id", align: "left" },
      { name: "app", align: "left" },
      { name: "content", align: "center" },
      { name: "submit", align: "left" },
      { name: "start", align: "left" },
      { name: "end", align: "left" },
      { name: "status", align: "left" },
      { name: "completion", align: "center" },
    ],
    rows,
    // rows: [
    //   {
    //     id: [logoGromacs, "Gromacs-1"],
    //     submit: (
    //       <SuiTypography variant="button" textColor="text" fontWeight="medium">
    //         2023-06-15 17:38:31
    //       </SuiTypography>
    //     ),
    //     start: (
    //       <SuiTypography variant="button" textColor="text" fontWeight="medium">
    //         2023-06-15 17:38:31
    //       </SuiTypography>
    //     ),
    //     end: (
    //       <SuiTypography variant="button" textColor="text" fontWeight="medium">
    //         2023-06-15 19:38:31
    //       </SuiTypography>
    //     ),
    //     status: (
    //       <SuiTypography variant="caption" textColor="text" fontWeight="medium">
    //         working
    //       </SuiTypography>
    //     ),
    //     completion: <Completion value={60} color="info" />,
    //   },
    //   {
    //     id: [logoGromacs, "Gromacs-2"],
    //     submit: (
    //       <SuiTypography variant="button" textColor="text" fontWeight="medium">
    //         2023-06-15 17:38:31
    //       </SuiTypography>
    //     ),
    //     start: (
    //       <SuiTypography variant="button" textColor="text" fontWeight="medium">
    //         2023-06-15 17:38:31
    //       </SuiTypography>
    //     ),
    //     end: (
    //       <SuiTypography variant="button" textColor="text" fontWeight="medium">
    //         2023-06-15 19:38:31
    //       </SuiTypography>
    //     ),
    //     status: (
    //       <SuiTypography variant="caption" textColor="text" fontWeight="medium">
    //         done
    //       </SuiTypography>
    //     ),
    //     completion: <Completion value={100} color="success" />,
    //   },
    // ],
  };
}
