/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

import { useAuth } from "../../../../auth-context/auth.context";
import AuthApi from "../../../../api/auth";

function ApiKeyPanel() {
  const [error, setError] = useState(undefined);
  const { user } = useAuth();
  const [tokenAPI, setTokenAPI] = useState(localStorage.getItem("token_api"));

  // const getTokenAPI = async (event) => {
  //   if (event) {
  //     event.preventDefault();
  //   }

  //   try {
  //     let response = await AuthApi.TokenAPI(user);
  //     if (response.data && response.data.success === false) {
  //       return setError(response.data.error);
  //     }

  //     setTokenAPI(response.data.token_api);
  //     localStorage.setItem("token_api", response.data.token_api);
  //   } catch (err) {
  //     console.log(err);
  //     if (err.response) {
  //       return setError(err.response.data.msg);
  //     }
  //     return setError("There has been an error.");
  //   }
  // };

  const refreshTokenAPI = async (event) => {
    if (event) {
      event.preventDefault();
    }

    try {
      let response = await AuthApi.GenerateTokenAPI(user);
      if (response.data && response.data.success === false) {
        return setError(response.data.msg);
      }

      setTokenAPI(response.data.new_token_api);
      localStorage.setItem("token_api", tokenAPI);
    } catch (err) {
      console.log(err);
      if (err.response) {
        return setError(err.response.data.msg);
      }
      return setError("There has been an error.");
    }
  };

  return (
    <Card id="delete-account">
      <SuiBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <SuiTypography variant="h6" fontWeight="medium">
          API Key
        </SuiTypography>
        <SuiButton variant="gradient" buttonColor="dark" onClick={refreshTokenAPI}>
          <Icon className="font-bold">refresh</Icon>
          &nbsp;&nbsp;&nbsp;generate new key
        </SuiButton>
      </SuiBox>
      <SuiBox p={2}>
        <SuiBox mb={2} lineHeight={1.25}>
          <SuiTypography variant="h6" textColor="text" fontWeight="medium">
            {tokenAPI ? tokenAPI : "api token not set"}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
      <SuiBox p={2} textAlign="center">
        <h6
          style={{
            fontSize: ".8em",
            color: "red",
            textAlign: "center",
            fontWeight: 400,
            transition: ".2s all",
          }}
        >
          {error}
        </h6>
      </SuiBox>
    </Card>
  );
}

export default ApiKeyPanel;
