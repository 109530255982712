/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// import SuiButton from "components/SuiButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
import Table from "examples/Table";
import styles from "layouts/tables/styles";
import { ConvertJobsToRows } from "layouts/jobs/data/jobsTableData";
import { useAuth } from "auth-context/auth.context";
// import JobApi from "api/job";
import UserApi from "api/user";
import SuiPagination from "components/SuiPagination";
// import SimpleTable from "./components/SimpleTable";

const columns = [
  { name: "app", align: "left" },
  { name: "id", align: "left" },
  { name: "content", align: "center" },
  { name: "submit", align: "left" },
  { name: "start", align: "left" },
  { name: "end", align: "left" },
  { name: "status", align: "left" },
];

function Tables() {
  const classes = styles();
  // const { columns: prCols } = JobTable({});
  let { user } = useAuth();
  const [totalNumber, setTotalNumber] = useState(localStorage.getItem("job_total_number"));
  const [jobs, setJobs] = useState(JSON.parse(localStorage.getItem("jobs")));
  const [prRows, setPrRows] = useState(ConvertJobsToRows(jobs));
  if (localStorage.getItem("job_offset") == null) {
    localStorage.setItem("job_offset", 0);
  }
  const [offset, setOffSet] = useState(parseInt(localStorage.getItem("job_offset")));

  const saveOffset = (newOffset) => {
    setOffSet(newOffset);
    localStorage.setItem("job_offset", newOffset);
  };

  const queryJobs = async (offset) => {
    try {
      let response = await UserApi.GetJobs({
        email: user.email,
        token_auth: user.token_auth,
        offset,
        count_req: 10,
      });

      if (response.data && response.data.reply_msg.success === false) {
        console.log(response.data.msg);
        return;
      }

      setTotalNumber(response.data.total_number);
      localStorage.setItem("job_total_number", response.data.total_number);
      let jobs = response.data.jobs;
      jobs.sort((a, b) => a.time_created > b.time_created);
      setJobs(jobs);
      localStorage.setItem("jobs", JSON.stringify(jobs));

      const rows = ConvertJobsToRows(response.data.jobs);
      // setPrRows(rows.map((row, index) => <li key={index}>{row.id}</li>));
      setPrRows(rows);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data.msg);
      }
      return;
    }
  };

  const clickPaginationLeftArrow = async (event) => {
    if (event) {
      event.preventDefault();
    }
    const new_offset = offset - 1 > 0 ? offset - 1 : 0;
    saveOffset(new_offset);
    await queryJobs(new_offset);
  };

  const clickPaginationRightArrow = async (event) => {
    if (event) {
      event.preventDefault();
    }
    const offsetMax = Math.floor(totalNumber / 10);
    const new_offset = offset + 1 <= offsetMax ? offset + 1 : offsetMax;
    saveOffset(new_offset);
    await queryJobs(new_offset);
  };

  const clickPaginationItem = async (event, key) => {
    if (event) {
      event.preventDefault();
    }
    saveOffset(key);
    await queryJobs(key);
  };

  const clickRefreshJobs = async (event) => {
    if (event) {
      event.preventDefault();
    }
    await queryJobs(offset);
  };

  const renderPagination = (
    <SuiPagination size="small">
      <SuiPagination item onClick={clickRefreshJobs}>
        <Icon>refresh</Icon>
      </SuiPagination>
      <SuiPagination item onClick={clickPaginationLeftArrow}>
        <Icon>keyboard_arrow_left</Icon>
      </SuiPagination>
      {Array.from({ length: Math.floor((totalNumber - 1) / 10) + 1 }, (v, i) => i).map((key) => {
        return (
          <SuiPagination
            item
            key={key}
            onClick={(e) => {
              clickPaginationItem(e, key);
            }}
            active={key == offset}
          >
            {key + 1}
          </SuiPagination>
        );
      })}
      <SuiPagination item onClick={clickPaginationRightArrow}>
        <Icon>keyboard_arrow_right</Icon>
      </SuiPagination>
    </SuiPagination>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <Card>
          <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
            <SuiBox display="flex" justifyContent="space-between">
              <SuiTypography variant="h6">
                {totalNumber} Job{totalNumber > 1 ? "s" : ""}
              </SuiTypography>
              {/* <SuiButton variant="gradient" buttonColor="dark" onClick={clickRefreshJobs}>
                <Icon className="font-bold">refresh</Icon>
              </SuiButton> */}
            </SuiBox>
            {renderPagination}
          </SuiBox>
          <SuiBox customClass={classes.tables_table}>
            {/* <SimpleTable rows={prRows} /> */}
            <Table columns={columns} rows={prRows} />
          </SuiBox>
        </Card>
      </SuiBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Tables;
